import request from "../request";

export function add(data) {
  return request({
    url: "/model-analysis/riskTraining/add",
    method: "post",
    data,
  });
}
export function remove(data) {
  return request({
    url: "/model-analysis/riskTraining/delete",
    method: "post",
    data,
  });
}
export function edit(data) {
  return request({
    url: "/model-analysis/riskTraining/update",
    method: "post",
    data,
  });
}
export function fetchList(data) {
  return request({
    url: "/model-analysis/riskTraining/queryWIthPage",
    method: "post",
    data,
  });
}
